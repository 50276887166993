import { ISidebarOption } from './session';
import { ResourceType } from '@ready/security.core';
import reportsSidebarChildren from './reportsSidebarChildren';

export const SIDEBAR_OPTIONS: ISidebarOption[] = [
  {
    label: 'Admins',
    url: '/ready/admins',
    adminOption: true,
    enabled: true,
    permissions: ['admins'],
  },
  {
    label: 'Locations',
    url: '/ready/locations',
    adminOption: true,
    enabled: true,
    permissions: ['locations'],
  },
  {
    label: 'Companies',
    url: '/ready/companiesSettings',
    adminOption: true,
    enabled: true,
    permissions: ['companies'],
  },
  {
    label: 'Reports',
    url: '',
    adminOption: false,
    enabled: true,
    children: reportsSidebarChildren,
    permissions: [],
  },
  {
    label: 'Orders',
    url: '/orders',
    adminOption: false,
    enabled: true,
    permissions: [ResourceType.order],
  },
  {
    label: 'Server Assignment',
    url: '/serverAssignment',
    adminOption: false,
    enabled: true,
    permissions: [ResourceType.tableAssignment],
  },

  {
    label: 'Users',
    url: '',
    adminOption: false,
    enabled: true,
    children: [
      {
        label: 'Users',
        url: '/users',
        adminOption: false,
        enabled: true,
        permissions: [ResourceType.user],
      },
      {
        label: 'Security Roles',
        url: '/securityRoles',
        adminOption: false,
        enabled: true,
        permissions: [ResourceType.securityRole],
      },
    ],
    permissions: [],
  },
  {
    label: 'Menus',
    url: '',
    adminOption: false,
    enabled: true,
    children: [
      {
        label: 'Location Menus',
        // Todo: change the route to /location-menus
        url: '/menu-builder',
        adminOption: false,
        enabled: true,
        permissions: [ResourceType.menu],
      },
      {
        label: 'Menu Builder',
        // Todo: change the route to /menu-builder
        url: '/shared-menu-items',
        adminOption: false,
        enabled: true,
        permissions: [ResourceType.templateMenu],
      },
      {
        label: 'Schedules',
        url: '/schedules',
        adminOption: false,
        enabled: true,
        permissions: [ResourceType.menuSchedule],
      },
      {
        label: 'Tags & Badges',
        url: '/tagsBadges',
        adminOption: false,
        enabled: true,
        permissions: [ResourceType.menuTagsBadges],
      },
    ],
    permissions: [],
  },
  {
    label: 'Location Settings',
    url: '/companyLocations',
    adminOption: false,
    enabled: true,
    permissions: [
      ResourceType.table,
      ResourceType.paymentProcessing,
      ResourceType.promoCodes,
      ResourceType.digitalMenu,
      ResourceType.orderSettings,
      ResourceType.serverFlow,
    ],
  },

  {
    label: 'Financial Services',
    url: '/financialServices',
    adminOption: false,
    enabled: true,
    children: [
      {
        label: 'Chargebacks',
        url: '/financialServices/chargeback',
        adminOption: false,
        enabled: true,
        permissions: [ResourceType.chargeback],
      },
    ],
    permissions: [ResourceType.chargeback],
  },
  {
    label: 'Company Settings',
    url: '/companySettings',
    adminOption: false,
    enabled: true,
    permissions: [ResourceType.company],
  },
];
